.skip-link {
  display: none;
  @media (--desktop){
    display: block;
    position: absolute;
    top: 80vh;
    left: 50%;
    z-index: var(--zIndex__top);
    transform: translateX(-50%);
    font: 400 1rem/1.4 'Ginora Sans', sans-serif;
    text-transform: capitalize;
    text-align: center;
    color: var(--orange);

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1.5em;
      margin-top: .5em;
      background: svg-load('../../../images/down_down.svg') 50%/contain no-repeat;
      animation: moveDown 1s infinite;
    }

    &:hover {
      color: var(--orange);

      &:after {
        animation-play-state: paused;
      }
    }
  }
}
@keyframes moveDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(50%);
  }
}
