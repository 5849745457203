.rev_slider_wrapper {
  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: var(--zIndex__bottom);
    background-color: var(--white);
  }

  &:before {
    top: 0;
    right: 60%;
    bottom: 60%;
    left: 0;
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }

  &:after {
    top: 75%;
    right: 0;
    bottom: 0;
    left: 0;
    clip-path: polygon(0 0, 50% 100%, 100% 0, 100% 100%, 0 100%);
  }
}
