.head {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 1em;
    left: 25em;
    width: calc(100% - 25em);
    height: 2px;
    background-color: var(--orange);
    @media (--phone) {
      visibility: hidden;
    }
  }
}
