.c-list {
  .wpb_single_image {
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);

    &:after {
      --h: 1em;

      content: '';
      position: absolute;
      top: 73%;
      bottom: 0;
      left: 0;
      width: 50%;
      background-color: var(--orange);
      clip-path: polygon(0 0, 100% 73%, 100% 100%, 0 100%);
    }

    .wpb_wrapper,
    .vc_single_image-wrapper,
    .vc_single_image-img {
      display: block;
      height: 100%;
    }

    .vc_single_image-img {
      object-fit: cover;
    }
  }

  &.c-list_count_4 {
    .wpb_single_image {
      width: calc(( 100% - 3em ) / 4);
      @media (--phone) {
        width: calc( 50% - .5em);
        margin-top: 1em;
      }
    }
  }

  &.c-list_count_3 {
    .wpb_single_image {
      width: calc(( 100% - 8em ) / 3);
      margin-top: 2em;
      @media (--phone) {
        width: calc( 50% - .5em);
      }
    }
  }
}
