.contact {
  & &__wrap {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    @media (--phone){
      text-align: center;

      > .wpb_column:last-child {
        padding-top: 2em;
      }

      .vc_single_image-img {
        max-width: 10em;
      }
    }
  }
}
