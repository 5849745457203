.o-products {
  .wpb_single_image {
    height: 18em;
    @media (--ipad) {
      height: 10em;
    }
    @media (--ipad_mini) {
      height: 10em;
    }
    @media (--phone) {
      height: 12em;
    }
  }
}
