.wpb_single_image {
  display: flex;
  flex-flow: column nowrap;

  .wpb_heading {
    order: 2;
    margin-top: 1em;
    text-transform: capitalize;
    font: 900 1.1rem/1.2 'Futura Hv BT', sans-serif;
    color: var(--black);
  }

  .prettyphoto {
    overflow: hidden;
    display: block;

    .vc_single_image-img {
      transition: transform .5s;
    }

    &:hover {
      .vc_single_image-img {
        transform: scale(1.1);
      }
    }
  }
}
