.o-controller {
  & &__wrap {
    .vc_row {
width: calc(100% / 3);
       @media (--phone) {
width: 50%;
       }

       .wpb_column > .vc_column-inner > .wpb_wrapper {
position: relative;
padding: 0 2em;
       }
    }
  }
}
