.mainnav {
  .menu {
    &-item {
      text-transform: capitalize;
    }
  }

  &#mainnav {
    .menu {
      &-item {
        padding: 0 2em;

        > a {
          font-size: 1.1rem;
          color: var(--orange);
        }

        &.current-menu-parent,
        &.current-product_cat-parent,
        &.current-product_cat-ancestor,
        &.current-menu-ancestor,
        &.current-menu-item,
        &.current_page_item {
          > a {
            position: relative;
            
            &:after {
              content: '';
              position: absolute;
              top: calc( 100% );
              left: 0;
              right: 0;
              height: 2px;
              background-color: var(--orange);
            }
          }
        }

        .sub-menu {
          left: 0;
          width: 9em;

          &:after {
            display: none;
          }

          .menu-item {
            padding: 0;

            a {
              width: 9em;
              padding: .4em 1em;
              line-height: 1;
              background-color: color(black a(.5));
              border-top: none;
              text-transform: capitalize;

              &:after {
                display: none;
              }

              &:hover {
                background-color: color(var(--black) a(.4));
              }
            }
          }
        }

        &:hover {
          .sub-menu {
            left: 0;
          }
        }
      }
    }
  }
}
