.o-certificates {
  .wpb_single_image {
    @media (--desktop) {
      &:nth-child(3n+2),
      &:nth-child(3n+3) {
        margin-left: 4em;
      }
    }
    @media (--phone) {
      &:nth-child(2n) {
        margin-left: 1em;
      }
    }
  }
}
