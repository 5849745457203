.o-controller {
  & &__icon {
    --wh: 4em;

    margin-top: 2em;
    cursor: pointer;

    .wpb_heading {
      font-size: 1rem;
    }

    .vc_single_image-wrapper {
      position: relative;
      width: var(--wh);
      height: var(--wh);
    }

    .vc_single_image-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}
