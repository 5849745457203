.home-projects {
  & &__head {
    display: flex;
    position: relative;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: .5em;
    font: 400 3rem/1.4 'Ginora Sans', sans-serif;

    &:before {
      content: '';
      position: absolute;
      bottom: 1em;
      left: 7em;
      width: calc(100% - 9em);
      height: 2px;
      background-color: var(--orange);
      @media (--phone){
        visibility: hidden;
      }
    }
  }
}
