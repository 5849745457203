.o-categories {
  .wpb_single_image {
    position: relative;
    width: calc(( 100% - 1em ) / 3);
    margin-top: 1em;
    @media (--phone) {
      width: calc(100% - 2em);
      margin: 2em auto 0;
    }

    .wpb_heading {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: flex-end;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: var(--zIndex__bottom);
      padding: 1em 1em 1.5em 1em;
      text-align: right;
      font: 700 2rem/1 "Futura Hv BT", sans-serif;
      color: var(--orange);

      &::before,
      &::after {
        color: white;
        font: 400 .8em/1.2 "Ginora Sans", sans-serif;
        text-transform: lowercase;
      }

      &::before {
        content: "\2212  products";
        position: absolute;
        right: 1em;
        transform: rotate(-90deg) translate(5em, 1.3em);
      }

      &::after {
        content: 'view more \2023';
        position: absolute;
        right: 1.8em;
        bottom: 1.3em;
        font-size: .5em;
      }
    }

    .vc_single_image-wrapper {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: color(black a(.4));
      }
    }

    &:hover {
      .wpb_heading {
        color: white;
      }

      .vc_single_image-wrapper::after {
        background-color: var(--orange);
      }
    }
  }
}
