.o-controller {
  & &__content {
    visibility: hidden;
    position: absolute;
    z-index: var(--zIndex__bottom);
    max-width: 40em;
    min-width: 20em;
    padding: 1em;
    background: var(--black);
    font-size: .9rem;
    color: white;

    &::before {
      content: '';
      position: absolute;
      border: 6px solid;
      @media (--phone) {
        visibility: hidden;
      }
    }

    &.is-active {
      visibility: visible;
    }

    &.o-controller__content_arrow_top {
      &::before {
        top: 0;
        left: 1em;
        transform: translate(0, -100%);
        border-color: transparent transparent var(--black) transparent;
        border-top: none;
      }
    }

    &.o-controller__content_arrow_right {
      &::before {
        top: 1em;
        right: 0;
        transform: translate(100%, 0);
        border-color: transparent transparent transparent var(--black);
        border-right: none;
      }
    }

    &.o-controller__content_arrow_bottom {
      &::before {
        bottom: 0;
        left: 1em;
        transform: translate(0, 100%);
        border-color: var(--black) transparent transparent transparent;
        border-bottom: none;
      }
    }

    &.o-controller__content_arrow_left {
      &::before {
        top: 1em;
        left: 0;
        transform: translate(-100%, 0);
        border-color: transparent var(--black) transparent transparent;
        border-left: none;
      }
    }
  }
}
