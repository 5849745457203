.header-clone {
  display: none;
}
.site-header {
  position: absolute !important;
  background-color: transparent !important;

  &.fixed {
    position: absolute !important;
  }
  
  @media (--phone){
    .site-logo {
      max-width: 70%;
    }
  }
}
