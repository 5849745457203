.woocommerce {
  .products {
    margin-bottom: 0 !important;

    .type-product.cat-parent_16 {
      .button.product_type_simple {
        display: none;
      }

      .woocommerce-loop-product__title,
      .product__brand {
        text-align: left;
        font: 400 1.5rem/1.2 'Ginora Sans', sans-serif;
        text-transform: capitalize;
        color: var(--black);
      }

      .product__brand {
        font-size: 1rem;
      }

      .woocommerce-LoopProduct-link {
        display: block;

        &:hover {
          .product__wrap {
            &:before {
              opacity: 1;
            }
          }

          .attachment-woocommerce_thumbnail {
            filter: none;
          }
        }
      }

      .product__wrap {
        position: relative;
        clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);

        &:before, 
        &:after {
          position: absolute;
        }

        &:after {
          --h: 1em;

          content: '';
          top: 73%;
          bottom: 0;
          left: 0;
          width: 50%;
          background-color: var(--orange);
          clip-path: polygon(0 0, 100% 73%, 100% 100%, 0 100%);
        }

        &:before {
          content: 'view';
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: color( var(--black) a(.7) );
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          font: 400 1rem/1.2 'Ginora Sans', sans-serif;
          color: var(--orange);
          text-decoration: underline;
          opacity: 0;
        }
      }

      .attachment-woocommerce_thumbnail {
        height: 20em;
        margin-bottom: 0;
        object-fit: cover;
        filter: brightness(.85);
      }
    }

    .product-category.cat-parent_15 {
      margin-right: 10px;
      @media (--desktop){
        width: calc(( 100% - 20px ) / 3) !important;
      }
      @media (--phone){
        width: 100% !important;
      }

      &.last {
        margin-right: 0;
      }

      a {
        display: block;
        position: relative;

        &:hover {
          .category__wrap {
            background-color: var(--orange);
          }

          .category__title {
            color: var(--white);
          }
        }
      }

      img {
        margin-bottom: 0;
      }

      .category__wrap { 
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        align-items: flex-end;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 10%;
        background-color: color(var(--black) a(.6));
        transition: background-color .4s;
      }

      .category__title {
        text-align: right;
        font: 700 1.8rem/1.2 'Ginora Sans', sans-serif;
        color: var(--orange);
        transition: color .4s;
      }

      .category__type {
        font: 400 1.5rem/1.2 'Ginora Sans', sans-serif;
        text-transform: lowercase;
        transform: rotate(-90deg) translate(40%, 260%);

        &:before {
          content: '';
          display: inline-block;
          margin-right: .5em;
          width: 2em;
          height: 1px;
          background-color: var(--white);
          vertical-align: middle;
        }
      }
    }
  }

  &.tax-product_cat {
    .woocommerce-breadcrumb,
    .woocommerce-result-count,
    .woocommerce-ordering {
      display: none;
    }

    #primary {
      float: none;
      width: 100%;
    }

    .products__head {
      margin: 1em 0 2em;
    }

    &.parent_15 {
      .type-product {
        --imageW: 30%;
        --p: 2.992em;
        float: none !important;
        position: relative;
        width: 100% !important;
        padding: 0 0 var(--p);
        margin-right: 0;

        &:last-child {
          padding: 0;
        }

        &:before {
          content: '';
          position: absolute;
          top: calc( var(--p) * -1 );
          width: 80%;
          height: 1px;
          background-color: color( var(--orange) l(+20%) );
        }

        &.last {
          @media (--ipad){
            margin-bottom: var(--p) !important;
          }
          @media (--ipad_mini){
            margin-bottom: var(--p) !important;
          }
          @media (--phone){
            margin-bottom: var(--p) !important;
          }
        }

        .button.product_type_simple {
          display: none;
        }

        .woocommerce-LoopProduct-link {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          align-items: center;
          pointer-events: none;
        }

        .product__image {
          --clip: 20%;
          position: relative;
          width: var(--imageW);
          @media (--phone){
            width: 100%;
          }

          &:after {
            --thickness: 5%;
            content: '';
            position: absolute;
            top: 50%;
            bottom: 0;
            background-color: var(--orange);
            @media (--phone){
              --thickness: 10%;
              top: calc(100% - var(--clip) - var(--thickness));
            }
          }

          .attachment-woocommerce_thumbnail {
            max-height: 15em;
            margin-bottom: 0;
            object-fit: cover;
          }
        }

        .product__wrap {
          width: calc(100% - var(--imageW) - 4%);
          text-align: left;
          @media (--phone){
            width: 100%;
          }
        }

        .woocommerce-loop-product__title {
          font: 900 1rem/1.4 'Futura Hv BT', sans-serif;
          color: var(--black);
          @media (--phone){
            margin-top: 1em;
          }
        }

        .product__content {
          color: var(--black);
        }

        &:nth-child(even){
          &:before {
            right: 0;
          }
          .product__image {
            order: 1;
            clip-path: polygon(0 0, calc( 100% - var(--clip)) 0, 100% 50%, calc(100% - var(--clip) ) 100%, 0 100%);
            @media (--phone) {
              clip-path: polygon( 0 0, 100% 0, 100% calc( 100% - var(--clip) ), 50% 100%, 0 calc( 100% - var(--clip) ) );
            }

            &:after {
              left: calc(100% - var(--clip) - var(--thickness));
              right: 0;
              clip-path: polygon(calc( 100% - var(--clip) ) 0, 100% 0, 100% 100%, 0 100%);
              @media (--phone) {
                left: 50%;
                clip-path: polygon( 0 calc( 100% - var(--clip) - var(--thickness) ), 100% 0, 100% 100%, 0 100%);
              }
            }
          }
          .product__wrap {
            order: 2;
          }
        }
        &:nth-child(odd){
          &:before {
            left: 0;
          }
          .product__image {
            order: 2;
            clip-path: polygon(var(--clip) 0, 100% 0, 100% 100%, var(--clip) 100%, 0 50%);
            @media (--phone){
              order: 1;
              clip-path: polygon( 0 0, 100% 0, 100% calc( 100% - var(--clip) ), 50% 100%, 0 calc( 100% - var(--clip) ) );
            }

            &:after {
              left: 0;
              right: calc(100% - var(--clip) - var(--thickness));
              clip-path: polygon(0 0, var(--clip) 0, 100% 100%, 0 100%);
              @media (--phone) {
                right: 50%;
                clip-path: polygon( 0 0, 100% calc( 100% - var(--clip) - var(--thickness) ), 100% 100%, 0 100%);
              }
            }
          }
          .product__wrap {
            order: 1;
            @media (--phone){
              order: 2;
            }
          }
        }

        &:first-child:before {
          display: none;
        }
      }
    }
  }
}
