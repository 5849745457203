@define-mixin formInput {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--black);
  font: 400 1rem/1.4 'Futura Bk BT', sans-serif;
  color: var(--black);

  &::placeholder {
    text-transform: capitalize;
    color: var(--black);
    opacity: 1;
  }
  &::-ms-placeholder {
    text-transform: capitalize;
    color: var(--black);
  }
  &:-ms-placeholder {
    text-transform: capitalize;
    color: var(--black);
  }
}
.wpcf7 {
  input {
    &[type="text"],
    &[type="email"],
    &[type="tel"] {
      @mixin formInput;
    }

    &[type="email"],
    &[type="tel"] {
      width: calc(50% - .5em);
      @media (--phone){
        width: 100%;
      }
    }

    &[type="email"] {
      margin-left: 1em;
      @media (--phone){
        margin-left: 0;
      }
    }

    &[type="text"] {
      width: 100%;
    }

    &[type="submit"] {
      margin-top: 1em;
      padding: .1em 1em;
      border-radius: 0;
      font: 400 1rem/1.2 'Futura Bk BT', sans-serif;
      color: var(--black);
      text-transform: uppercase;
      box-shadow: 0 3px 0 0 color(var(--orange) b(+20%));
      @media (--phone){
        font-size: 1.2rem;
      }
    }
  }

  textarea {
    width: 100%;
    @mixin formInput;
    resize: none;
  }
}
