.contact {
  & &__head {
    &:before {
      left: 14em;
      width: calc(100% - 14em);
    }

    &_kl {
      &:before {
        left: 18em;
        width: calc(100% - 18em);
      }
    }

    &-title {
      font-size: 1.5rem;
    }
  }
}
