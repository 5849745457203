:root {
  --orange: #f8ba14;
  --black: #1a1a1a;
  --white: white;

  --zIndex__top: 9999;
  --zIndex__middle: 999;
  --zIndex__bottom: 99;
}

@define-mixin headTitle {
  font: 400 2rem/1.2 'Ginora Sans', sans-serif;
  text-transform: uppercase;

  span {
    font-size: .8em;
    text-transform: lowercase;
  }
}
