.wrap-mo {
  @media (--phone){
    display: flex;
    flex-flow: row wrap;

    .vc_column_container:last-child {
      order: -1;
    }
  }
}
