.contact {
  & &__cover {
    margin-top: -36%;
    @media (--phone){
      margin-top: -20%;
    }

    img {
      width: 24em;
    }
  }
}
