.site-footer {
  font: 400 12px/1.2 'Futura Bk BT', sans-serif;
  text-align: center;

  em {
    font-style: normal;
    font-family: 'Futura Hv BT';
    font-weight: 900;
  }

  &&_yellow {
    background-color: var(--orange);
    color: var(--black);
  }
}
