.content {
  em {
    font-style: normal;
    color: var(--orange);
  }

  strong,
  em {
    font-weight: 900;
    font-family: 'Futura Hv BT';
  }
}
