.o-product {
  & &__slider {
    .theme-default {
      position: relative;
      clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%);

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 74%;
        z-index: var(--zIndex__bottom);
        background-color: var(--orange);
        clip-path: polygon(75% 0, 100% 0, 25% 100%, 0 100%);
      }

      .nivo-directionNav a {
        display: none;
        z-index: var(--zIndex__middle);
        border-radius: 50%;
        background-color: white;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          right: 50%;
          width: 10px;
          height: 10px;
          border-bottom: 1px solid var(--orange);
        }

        &:first-child::before {
          border-left: 1px solid var(--orange);
          transform: rotate(45deg) translate(0, -7px);
        }

        &:last-child::before {
          border-right: 1px solid var(--orange);
          transform: rotate(-45deg) translate(5px, 0);
        }
      }

      .nivoSlider {
        box-shadow: none;
      }
    }

    .nivo-caption {
      display: none !important;
    }

    .nivo-controlNav {
      display: none !important;
    }
  }
}
