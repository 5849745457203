@font-face {
  font-family: 'Futura';
  src: url('../fonts/Futura-Bold.eot');
  src: url('../fonts/Futura-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Futura-Bold.woff2') format('woff2'),
  url('../fonts/Futura-Bold.woff') format('woff'),
  url('../fonts/Futura-Bold.ttf') format('truetype'),
  url('../fonts/Futura-Bold.svg#Futura-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Ginora Sans';
  src: url('../fonts/GinoraSans-SemiBold.eot');
  src: url('../fonts/GinoraSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GinoraSans-SemiBold.woff2') format('woff2'),
  url('../fonts/GinoraSans-SemiBold.woff') format('woff'),
  url('../fonts/GinoraSans-SemiBold.ttf') format('truetype'),
  url('../fonts/GinoraSans-SemiBold.svg#GinoraSans-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Ginora Sans';
  src: url('../fonts/GinoraSans-Regular.eot');
  src: url('../fonts/GinoraSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GinoraSans-Regular.woff2') format('woff2'),
  url('../fonts/GinoraSans-Regular.woff') format('woff'),
  url('../fonts/GinoraSans-Regular.ttf') format('truetype'),
  url('../fonts/GinoraSans-Regular.svg#GinoraSans-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Bk BT';
  src: url('../fonts/FuturaBT-Book.eot');
  src: url('../fonts/FuturaBT-Book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/FuturaBT-Book.woff2') format('woff2'),
  url('../fonts/FuturaBT-Book.woff') format('woff'),
  url('../fonts/FuturaBT-Book.ttf') format('truetype'),
  url('../fonts/FuturaBT-Book.svg#FuturaBT-Book') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ginora Sans';
  src: url('../fonts/GinoraSans-Bold.eot');
  src: url('../fonts/GinoraSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GinoraSans-Bold.woff2') format('woff2'),
  url('../fonts/GinoraSans-Bold.woff') format('woff'),
  url('../fonts/GinoraSans-Bold.ttf') format('truetype'),
  url('../fonts/GinoraSans-Bold.svg#GinoraSans-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Hv BT';
  src: url('../fonts/FuturaBT-Heavy.eot');
  src: url('../fonts/FuturaBT-Heavy.eot?#iefix') format('embedded-opentype'),
  url('../fonts/FuturaBT-Heavy.woff2') format('woff2'),
  url('../fonts/FuturaBT-Heavy.woff') format('woff'),
  url('../fonts/FuturaBT-Heavy.ttf') format('truetype'),
  url('../fonts/FuturaBT-Heavy.svg#FuturaBT-Heavy') format('svg');
  font-weight: 900;
  font-style: normal;
}

