.btn {
  &&_themes_arrow {
    position: relative;

    &:after {
      content: '';
      display: inline-block;
      margin-left: .6em;
      border-width: .4em 0 .4em .6em;
      border-color: transparent transparent transparent var(--white);
      border-style: solid;
      vertical-align: middle;
    }

    &_orange {
      &:after {
        border-color: transparent transparent transparent var(--orange);
      }
    }
  }
}
