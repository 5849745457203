.c-flex {
  > .wpb_column > .vc_column-inner > .wpb_wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    > .vc_row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &--center {
    > .wpb_column > .vc_column-inner > .wpb_wrapper {
      justify-content: center;
    }
  }
}
