.footer-widgets {
  --clipH: 35%;
  padding: 10% 0 2em;
  font: 400 1rem/1.4 'Futura Bk BT', sans-serif;
  clip-path: polygon(0 var(--clipH), 50% 0, 100% var(--clipH), 100% 100%, 0 100%);
  @media (--ipad){
    --clipH: 20%;
  }
  @media (--ipad_mini){
    --clipH: 20%;
  }
  @media (--phone){
    --clipH: 10%;
    text-align: center;
  }

  b {
    font-family: 'Futura Hv BT';
  }

  address {
    margin-bottom: 0;
  }

  .widget-title {
    margin-top: 0;
    padding-bottom: 1em;
    font-family: 'Ginora Sans';
    color: var(--white);
  }

  #custom_html-2 {
    margin-bottom: 0;
    padding-top: 0;
  }

  #media_image-2,
  #custom_html-2 {
    text-align: center;
  }

  #media_image-2 {
    @media (--desktop){
      margin-bottom: 1em;
    }
    @media (--phone){
      margin-bottom: 1em;
      
      .image {
        max-width: 7em !important;
      }
    }
  }

  #custom_html-3 {
    padding-top: 2em;
    margin-bottom: 0;
  }

  #custom_html-4 {
    padding-top: 2em;
    margin-bottom: 0;
  }

  &&_pp {
    #custom_html-3 {
      display: none;
    }

    #custom_html-2,
    #media_image-2 {
      text-align: center;
    }

    #media_image-2 {
      @media (--ipad){
        margin: 2em 0 0;
        padding-bottom: .5em;
      }
      @media (--ipad_mini){
        margin: 2em 0 0;
        padding-bottom: .5em;
      }
      @media (--phone){
        margin: 2em 0 0;
        padding-bottom: .5em;
      }
    }

    .footer-widgets__wrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      @media (--phone){
        .col-md-4.col-xs-12 {
          order: -1;
          padding-bottom: 2em;
        }
      }
    }
    
    .wpcf7 {
      margin-top: 1em;

      input {
        &[type="text"],
        &[type="tel"],
        &[type="email"] {
          width: calc(( 100% - 2em ) / 3);
          @media (--phone){
            width: 100%;
          }
        }

        &[type="tel"],
        &[type="email"] {
          margin-left: 1em;
          @media (--phone){
            margin-left: 0;
          }
        }

        &[type="submit"] {
          background-color: var(--white);
          border: none;
          box-shadow: 0 2px 0 0 color( var(--white) b(+40%) );
        }
      }

      textarea {
        margin-top: 1em;
      }
    }
  }
}
